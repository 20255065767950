// Suite Actions

import { push } from 'connected-react-router';
import AvailabilityApiClient from '../../../api/clients/AvailabilityApiClient';
import routes from '../../../routes';
import { OFFER_CODE_TYPE } from '../../../utilities/constants';
import plansActionTypes from '../Plans/plans.types';
import { gaTrackingPlanSearchErrorCode } from '../utilities/util';
import suiteActionTypes from './suite.types';

const MISMATCH = 'RATES_MISMATCH';

export const pushImpressionsAnalyticsData = eventProps => dispatch => {
  dispatch({
    type: suiteActionTypes.GTM_SUITE_IMPRESSIONS,
    eventProps
  });
};
export const pushToPackagesPage = () => dispatch => {
  dispatch(push(routes.plan.activities));
};
export const pushToDiningPackagesPage = () => dispatch => {
  dispatch(push(routes.plan.dining));
};
export const pushToPayment = () => dispatch => {
  dispatch(push(routes.plan.payment));
};
export const selectSuite = (suite, eventProps) => dispatch => {
  dispatch({ type: suiteActionTypes.SELECT_SUITE, selectedSuite: suite, eventProps });
};
export const getDetailedAvailability = (requestObject, failureCallback = () => {}) => async (dispatch, getState) => {
  const skipCompareValidation = requestObject.skipCompareValidation ? requestObject.skipCompareValidation : false;
  delete requestObject.skipCompareValidation;

  try {
    dispatch({
      type: suiteActionTypes.GET_SUITE_DETAILED_AVAILABILITY_INFO,
      getAvailabilityLoading: true
    });
    dispatch({
      type: suiteActionTypes.GET_SUITE_PLAN_PACKAGES_INFO_TYPE,
      getPlanPackageInfoLoading: true
    });

    const selectedSuite = getState().suite.selectedSuite;
    const suiteCode = selectedSuite.suiteCode;
    requestObject.suiteCode = suiteCode;
    const selectedRate = selectedSuite.nightlyRate;
    const offerType = getState().plans.offerType || '';

    const apiClient = new AvailabilityApiClient('v2.3');
    const response = await apiClient.getAvailabilityBySuite(suiteCode, requestObject);

    if (!response.data) {
      const errorCode = await response.json();

      if (errorCode) {
        gaTrackingPlanSearchErrorCode(JSON.stringify(errorCode));
        dispatch({
          type: suiteActionTypes.GET_SUITE_DETAILED_AVAILABILITY_ERROR,
          getAvailabilityLoading: false,
          suiteDetailedAvailability: []
        });
        dispatch({
          type: suiteActionTypes.GET_SUITE_PACKAGE_AVAILABILITY_INFO_ERROR,
          packageAvailabilityList: []
        });
        dispatch({
          type: plansActionTypes.SET_PLANS_AVAILABILITY_DETAILS_ERROR,
          error: {
            errorCode: errorCode,
            offerCode: requestObject.OfferCode
          }
        });
        failureCallback();
        return;
      }
    }

    if (response.data.suiteRates && response.data.suiteRates.length > 0) {
      response.data.suiteRates = response.data.suiteRates.map(rate => {
        rate['roomAdjustmentTax'] = rate['roomAdjustmentTax'] || 12.5;
        return rate;
      });
    }

    dispatch({
      type: suiteActionTypes.GET_SUITE_DETAILED_AVAILABILITY_SUCCESS,
      getAvailabilityLoading: false,
      suiteDetailedAvailability: [...response.data.suiteRates],
      suiteLcoPackage: response.data.lco,
      flexTripAllowed: response.data.isFlexTripAllowed ?? false,
      flexPackage: response.data.flexTripPackage,
      dayType: response.data.dayType,
      personalizationPackageRules: response.data.pr,
      personalizationResponses: response.data.rr,
      responseHasPackages: response.data.packageTable && response.data.packageTable.length > 0 ? true : false,
      suiteDetailsFetchSuccess: true,
      availabilityKey: response.data?.availabilityKey,
      taxesAndFeesDetailAvailability: response.data?.taxesAndFees
    });

    dispatch({ type: suiteActionTypes.SET_SUITE_PERSONALIZATION_PACKAGES });

    dispatch({
      type: suiteActionTypes.SET_SUITE_PLAN_PACKAGES_INFO_SUCCESS,
      planPackages: response.data.packageTable,
      getPlanPackageInfoLoading: false
    });

    const isNotSameOffer = offerType !== OFFER_CODE_TYPE.group;
    const isRateNotSame = selectedRate
      ? selectedRate.toFixed(2) !== response.data.suiteRates[0].totalNightlyRate.toFixed(2)
      : false;

    if (!skipCompareValidation && isNotSameOffer && isRateNotSame && !requestObject.isDateChanged) {
      gaTrackingPlanSearchErrorCode(MISMATCH);

      // WILL BE COMMENTED BY US 44687 FOR TESTING MISMATCH
      // dispatch({
      //   type: suiteActionTypes.GET_SUITE_DETAILED_AVAILABILITY_INFO,
      //   suiteRateMismatched: true
      // });
    }
  } catch (err) {
    gaTrackingPlanSearchErrorCode(JSON.stringify(err));
    dispatch({
      type: suiteActionTypes.GET_SUITE_DETAILED_AVAILABILITY_ERROR,
      getAvailabilityLoading: false,
      suiteStoreErrorMessage: `Oops! ${
        err.validationErrors && err.validationErrors.errorCode ? err.validationErrors.errorCode : err.message
      }`,
      suiteDetailedAvailability: [],
      getPlanPackageInfoLoading: false
    });
    dispatch({
      type: suiteActionTypes.CLEAR_SUITE_SELECTED
    });
    failureCallback();
    throw err;
  }
};
export const clearSelectedSuite = () => {
  return {
    type: suiteActionTypes.CLEAR_SUITE_SELECTED,
    selectedSuite: null,
    suiteRateMismatched: false,
    suiteDetailedAvailability: []
  };
};

/**
 * Post API call to get upgraded suite details
 */

export const updateSuiteRates = newSuiteRates => dispatch => {
  dispatch({
    type: suiteActionTypes.SET_SUITE_NEW_RATES,
    suiteDetailedAvailability: newSuiteRates
  });
};
export const resetAvailabilityCall = () => dispatch => {
  dispatch({
    type: suiteActionTypes.GET_SUITE_DETAILED_AVAILABILITY_RESET
  });
};
export const updateRateCodes = (requestObject, newSelectedSuite) => async (dispatch, getState) => {
  delete requestObject.skipCompareValidation;

  try {
    dispatch({
      type: suiteActionTypes.GET_SUITE_DETAILED_AVAILABILITY_INFO,
      getAvailabilityLoading: true
    });

    const suiteCode = newSelectedSuite.suiteCode;
    requestObject.suiteCode = suiteCode;
    const apiClient = new AvailabilityApiClient('v2.3');
    const response = await apiClient.getAvailabilityBySuite(suiteCode, requestObject);

    if (!response.data) {
      const errorCode = await response.json();

      if (errorCode) {
        dispatch({
          type: suiteActionTypes.GET_SUITE_DETAILED_AVAILABILITY_ERROR,
          getAvailabilityLoading: false,
          suiteDetailedAvailability: []
        });
        dispatch({
          type: plansActionTypes.SET_PLANS_AVAILABILITY_DETAILS_ERROR,
          error: {
            errorCode: errorCode,
            offerCode: requestObject.OfferCode
          }
        });
        return;
      }
    }

    if (response.data.suiteRates && response.data.suiteRates.length > 0) {
      response.data.suiteRates = response.data.suiteRates.map(rate => {
        rate['roomAdjustmentTax'] = rate['roomAdjustmentTax'] || 12.5;
        return rate;
      });
    }

    dispatch({
      type: suiteActionTypes.GET_SUITE_DETAILED_AVAILABILITY_SUCCESS,
      getAvailabilityLoading: false,
      suiteDetailedAvailability: [...response.data.suiteRates],
      suiteLcoPackage: response.data.lco,
      flexTripAllowed: response.data.isFlexTripAllowed ?? false,
      flexPackage: response.data.flexTripPackage,
      dayType: response.data.dayType,
      personalizationPackageRules: response.data.pr,
      personalizationResponses: response.data.rr,
      responseHasPackages: response.data.packageTable && response.data.packageTable.length > 0 ? true : false,
      suiteDetailsFetchSuccess: true,
      availabilityKey: response.data?.availabilityKey,
      taxesAndFeesDetailAvailability: response.data?.taxesAndFees
    });

    dispatch({ type: suiteActionTypes.SET_SUITE_PERSONALIZATION_PACKAGES });

    dispatch({
      type: suiteActionTypes.SET_SUITE_PLAN_PACKAGES_INFO_SUCCESS,
      planPackages: response.data.packageTable,
      getPlanPackageInfoLoading: false
    });
    dispatch({ type: suiteActionTypes.SELECT_SUITE, selectedSuite: newSelectedSuite });
  } catch (err) {
    gaTrackingPlanSearchErrorCode(JSON.stringify(err));
    dispatch({
      type: suiteActionTypes.GET_SUITE_DETAILED_AVAILABILITY_ERROR,
      getAvailabilityLoading: false,
      suiteStoreErrorMessage: `Oops! ${
        err.validationErrors && err.validationErrors.errorCode ? err.validationErrors.errorCode : err.message
      }`,
      suiteDetailedAvailability: [],
      getPlanPackageInfoLoading: false
    });
    dispatch({
      type: suiteActionTypes.CLEAR_SUITE_SELECTED
    });

    throw err;
  }
};
